import axios from 'axios'
import qs from 'qs'
import { getToken, setToken, removeToken } from './auth'

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

axios.defaults.baseURL = '/api'

axios.defaults.timeout = 20000

axios.interceptors.request.use(
    config => {
        if (getToken()) {
            config.headers['Authorization'] = 'Bearer ' + getToken()
        }
        if (config.method === 'post') {
            config.data = qs.stringify(config.data)
            config.params = undefined
        }
        return config
    },
    error => Promise.resolve(error.response || error)
)

axios.interceptors.response.use(
    response => {
        if (response.data && response.data.code != 200 && response.data.msg) {
            console.log(response.data.msg)
        }
        if (response.data && response.data.code == 403) {
            console.log('登录过期')
        }
        return response
    },
    error => {
        if (error.response && error.response.data.msg) {
            console.log(error.response.data.msg)
        } else if (error.message) {
            if (error.code && error.code == "ECONNABORTED") {
                console.log('连接超时')
            } else {
                console.log(error.message)
            }
        }
        return Promise.resolve(error.response || error)
    }
)

export const fetch = (url, data = {}, method = 'get') => {
    return new Promise((resolve, reject) => {
        axios({
            url,
            data: method == 'post' ? data : {},
            params: method == 'post' ? {} : data,
            method
        }).then(res => resolve(res.data))
    })
}