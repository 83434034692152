// 公共样式
import '@/style/scss/index.scss'

import { fetch } from './axios'
import axios from 'axios'
import { getToken, getQueryVariable, setSpm } from './auth'

const getSiteInfo = async () => {
    const data = await fetch('/v1/front/common_info')
    if (data.code == 0) {
        let ipc = data.data.website_info.site_icp ? `<a class="site-ipc" target="_blank" href="https://beian.miit.gov.cn/">${data.data.website_info.site_icp}</a>` : '';
        $('.logo').html(`<img src="${data.data.website_info.logo}">`)
        $('.footer-col-logo img').attr('src', data.data.website_info.logo)
        $('.site-name').html(data.data.website_info.index_host + ipc)
        let favicon = document.head.querySelector("link")
        favicon.href = data.data.website_info.logo
        document.title = data.data.website_info.title + ' - ' + document.title
        $('#nav li.nav-act').css({'backgroundColor': data.data.website_info.color, 'borderRightColor': data.data.website_info.color})
        $('#nav li.nav-act a').css({'color': '#fff'})
        $('.login-btn').attr('href', `//${data.data.website_info.host}/login`)
        $('.register-btn').attr('href', `//${data.data.website_info.host}/register`)
        $('#nav li a.publish-nav').attr('href', `//${data.data.website_info.host}`)
        $('#nav li a.publish-nav-tool').attr('href', `//${data.data.website_info.host}/tool/search`)
        let imgs =$('.swiper-wrapper').find('.banner-nav')
        for (let i = 0; i < imgs.length; i++) {
            $(imgs[i]).attr('href', `//${data.data.website_info.host}`)
        }
    }
}
getSiteInfo()

const getUserInfo = async () => {
    const data = await fetch('/v1/front/user/info')
    if (data.code == 0) {
        $('.login_before span').html(data.data.mobile)
        $('.login_after').hide()
        $('.login_before').show()
    }
}

const subVisit = async () => {
    axios({
        method: 'post',
        url: '/crmlog/v1/visit',
        baseURL: '/',
        data: {
            promotion_code: getQueryVariable('spm')
        }
    });
}

if (getToken()) {
    getUserInfo()
}

if (getQueryVariable('spm')) {
    setSpm()
    subVisit()
}