import Cookies from 'js-cookie'

let TokenKey = 'trafficUser_token'
let spmKey = 'trafficUser_spm'

export const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] == variable){return pair[1];}
  }
  return(false);
}

const hostname = location.hostname

const testSite = [
  'localhost',
  '192.168.31.153',
]

let isTest = testSite.some(item => {
  return hostname.includes(item)
})

let cookieDomain = {}

if (!isTest) {
  let domain = hostname.split('.').slice(-2).join('.')
  cookieDomain = {path: '/', domain: domain}
} else {
  TokenKey = 'test_trafficUser_token'
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setSpm() {
  return Cookies.set(spmKey, getQueryVariable('spm'), cookieDomain)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
